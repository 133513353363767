<template>
  <div class="card shadow p-4 text-center bg-gradient-neutral w-75">
    <div v-if="!phone_verified">
      <h3 class="text-body" >
        We've sent a verification code to
        <span class="text-body">{{ phone }}</span>
      </h3>
      <span class="text-sm text-muted">Please fill the code below and click verify to verify your phone</span>
      <div class="row mt-2" >
        <div class="col-md-12">
          <input class="form-control"  v-model="code" >
        </div>
        <div class="col mt-2">
          <button class="btn btn-sm btn-default" @click="verify_phone">
            <i class="fa fa-check"></i> Verify
          </button>
          <button class="btn btn-sm btn-default" @click="resend_phone"><i class="fa fa-send"></i> Resend code</button>
        </div>
      </div>
    </div>
    <div v-else>
      <h3 class="text-body" >
        Phone number is verified
      </h3>
    </div>

    <h3 v-if="phone_verified" class="text-white">
      Phone is verified successfully
    </h3>
    <em v-if="phone_verified" class="fas fa-5x fa-check-circle"></em>
  </div>
</template>

<script>
import axios from "axios";
import { alert } from "../../utils/alertUtils";
import {router} from "@/router";
export default {
  name: "VerifyEmail",
  data() {
    return {
      phone_verified: false,
      code:""

    };
  },
  computed:{
    phone:function () {
      console.log(this.$store.state.auth)
      return this.$store.state.auth.userProfile.phone_number
    }
  },
  methods: {
    verify_phone: function () {
      axios
        .put(
          `${process.env.VUE_APP_BACKEND_URL}/api/verify_phone/${this.code}`,
            {},
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.getToken,
              },
            }
        )
        .then((res) => {
          this.$store.dispatch("update_user_profile", res);
          this.phone_verified = true;
          if(res.data.has_set_password){
            alert.success_center('Phone verified, You can login now')
            this.$store.dispatch("logout")
          }else{
           router.push({name: "Set Password"})
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    resend_phone: function () {
      axios
        .post(
            `${process.env.VUE_APP_BACKEND_URL}/api/resend/verify_phone/`,
            {},
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.getToken,
              },
            }
        )
        .then(() => {
          alert.success_center('Code Sent!')
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {

  },
};
</script>

<style>
</style>